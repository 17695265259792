'use strict';

angular.module('informaApp')
    .directive('infAdminTooltips', ['TooltipsService', function (TooltipsService) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-admin-tooltips/template.ptl.html',

            scope: {},

            defaultTooltips: [
                { name: "Diseases", key: "diseases" },
                { name: "Companies", key: "companies" },
                { name: "Drug Classes", key: "drugClasses" },
                { name: "Timeframe", key: "timeframe" },
                { name: "Molecule", key: "molecule" },
                { name: "Target", key: "target" },
                { name: "Regulatory", key: "regulatory" },
                { name: "Drug Name", key: "drugName" },
                { name: "Routes", key: "routes" },
                { name: "Save this search", key: "saveSearch" },
                { name: "Lead/Partner", key: "companyMode" },
                { name: "Last Data Update", key: "dataUpdate" },
                { name: 'PTS', key: 'pts'},
                { name: 'LOA', key: 'loa'},
                { name: 'POA ', key: 'poa'},
                { name: 'PoTS', key: 'pots'},
                { name: 'Duration', key: 'duration'},
                { name: 'Number of transitions', key: 'numberOfTransition'}
            ],

            concatTooltips: function (tooltips) {
                var keys = tooltips.map(function (x) {
                    return x.key;
                });

                for (var i = 0; i < this.defaultTooltips.length; i++){
                    if (keys.indexOf(this.defaultTooltips[i].key) < 0){
                        tooltips.push(this.defaultTooltips[i]);
                    }
                }

                return tooltips;
            },

            link: function (scope, element, attrs) {
                var this_ = this;

                TooltipsService.getAllTooltips()
                    .then(function (response) {
                        var tooltips = response.map(function (x) {
                            x.originalText = x.text;
                            return x;
                        });

                        scope.tooltips = this_.concatTooltips(tooltips);
                    });

                scope.apply = function (tooltip) {
                    TooltipsService.createOrUpdate(tooltip.key, tooltip.name, tooltip.text);
                    tooltip.originalText = tooltip.text;
                    tooltip.editable = false;
                };

                scope.cancel = function (tooltip) {
                    tooltip.text = tooltip.originalText;
                    tooltip.editable = null;
                };

                scope.editTooltip = function (tooltip) {
                    var isEditable = !tooltip.editable;

                    for (var i = 0; i < scope.tooltips.length; i++){
                        scope.tooltips[i].editable = null;
                    }

                    if (!isEditable){
                        scope.cancel(tooltip);
                        return;
                    }

                    tooltip.editable = isEditable;
                };
            }
        };
    }]);